#root {
	height: 100%;
}

.white-bg {
	background-color: white;
}
/* refactor all center flex box */
.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.full-width-height {
	width: 100%;
	height: 100%;
}

.flex-space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flex-center-col {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.flex-center-col-start {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
}

.flex-space-around {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
}

.invisible-scrollbar::-webkit-scrollbar {
	display: none;
	width: 0px;
	/* Remove scrollbar space */
	background: transparent;
	/* Optional: just make scrollbar invisible */
	height: 0px;
}
